import React, {FC} from "react";
import {StyledContactInformation} from "./ContactInformation.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import {Translate} from "../../../../lang/Translate.component";
import {translate} from "../../../../lang/TranslationUtils";
import {getGoogleMapsLink} from "../../../../common/components/footer/footerAdress/service/FooterAdress.service";
import {ExternalLink} from "../../../../common/components/externalLink/ExternalLink.component";
import {LinedContainer} from "../../../../common/components/linedContainer/LinedContainer.component";
import {LineProps} from "../../../../common/components/buttonWithLines/ButtonWithLines.component";
import {Colors} from "../../../../common/style/ColorUtils";

interface ContactInformationProps {
    className?: string;
}

export const ContactInformation: FC<ContactInformationProps> = (props: ContactInformationProps) => {
    const topLineProps: LineProps = {
        left: "-60px", length: "190px", top: "-20px"
    };

    const bottomLineProps: LineProps = {
        left: "220px", length: "190px", top: "180px"
    };

    const leftLineProps: LineProps = {
        left: "-20px", length: "120px", top: "-40px"
    };

    const rightLineProps: LineProps = {
        left: "400px", length: "140px", top: "70px"
    };

    return <StyledContactInformation className={props.className}>
        <LinedContainer
            topLine={topLineProps}
            bottomLine={bottomLineProps}
            leftLine={leftLineProps}
            rightLine={rightLineProps}
            linesColor={Colors.GRAY}
            linesWidth={"1px"}
        >
            <div className={"contact-information"}>
                <div className={"contact-information-row"}>
                    <div className={"contact-information-icon"}><FontAwesomeIcon icon={faEnvelope}/></div>
                    <a href={"mailto:" + translate("CONTACT_EMAIL")} className={"contact-information-text"}>
                        <Translate translationKey={"CONTACT_EMAIL"}/>
                    </a>
                </div>
                <div className={"contact-information-row"}>
                    <div className={"contact-information-icon"}><FontAwesomeIcon icon={faPhone}/></div>
                    <a href={"tel:" + translate("CONTACT_PHONE_NUMBER")} className={"contact-information-text"}>
                        <Translate translationKey={"CONTACT_PHONE_NUMBER"}/>
                    </a>
                </div>
                <div className={"contact-information-row"}>
                    <div className={"contact-information-icon"}><FontAwesomeIcon icon={faLocationDot}/></div>
                    <ExternalLink className={"contact-information-text"} to={getGoogleMapsLink()}>
                        <Translate translationKey={"CONTACT_ADDRESS"}/>
                    </ExternalLink>
                </div>
            </div>
        </LinedContainer>
    </StyledContactInformation>;
};
