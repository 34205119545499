import * as React from "react"
import {StyledBookings} from "./Bookings.style";
import {Title} from "../../common/components/title/Title.component";
import {Colors} from "../../common/style/ColorUtils";
import {BookingsDescription} from "./bookingsDescription/BookingsDescription.component";
import {BookingsContact} from "./bookingsContact/BookingsContact.component";
import {BookingsMap} from "./bookingsMap/BookingsMap.component";
import {Spacer} from "../../common/components/spacer/Spacer.style";

export const BookingsComponent = () => {
    return <StyledBookings>
        <Title title={"BOOKINGS"} panelsColor={Colors.WHITE} textColor={Colors.WHITE}/>
        <BookingsDescription/>
        <Spacer height={"17px"}/>
        <BookingsContact/>
        <Spacer height={"17px"}/>
        <BookingsMap/>
    </StyledBookings>;
}
