import React, {FC} from "react";
import {StyledBookingsDescription} from "./BookingsDescription.style";
import {Colors} from "../../../common/style/ColorUtils";
import {MediumTitle} from "../../../common/components/mediumTitle/MediumTitle.component";
import {BookingsDescriptionTile} from "./bookingsDescriptionTile/BookingsDescriptionTile.component";

interface BookingsDescriptionProps {
}

export const BookingsDescription: FC<BookingsDescriptionProps> = (props: BookingsDescriptionProps) => {
    return <StyledBookingsDescription>
        <MediumTitle title={"HOW_DOES_IT_WORK"} textColor={Colors.WHITE} backgroundColor={Colors.BLACK}
                     centered={true}/>
        <div className={"instructions"}>
            {Array.from(Array(6)).map((value, index) =>
                <BookingsDescriptionTile key={index} index={index + 1}/>)}
        </div>
    </StyledBookingsDescription>;
};
