import React, {FC} from "react";
import {StyledBookingsDescriptionTile} from "./BookingsDescriptionTile.style";
import {Translate} from "../../../../lang/Translate.component";

interface BookingsDescriptionTileProps {
    index: number;
}

export const BookingsDescriptionTile: FC<BookingsDescriptionTileProps> = (props: BookingsDescriptionTileProps) => {
    return <StyledBookingsDescriptionTile>
        <div className={"content"}>
            <div className={"number"}>
                {props.index}
            </div>
            <div className={"right-panel"}>
                <div className={"title"}>
                    <Translate translationKey={"BOOKINGS_DESCRIPTION_TITLE_" + props.index}/>
                </div>
                <div className={"description"}>
                    <Translate translationKey={"BOOKINGS_DESCRIPTION_" + props.index}/>
                </div>
            </div>
        </div>
    </StyledBookingsDescriptionTile>;
};
