import styled from "styled-components";
import {Colors} from "../../../../common/style/ColorUtils";

export const StyledContactForm = styled.div`
  text-align: center;
  
  form {
    font-size: 18px;
    font-family: bely;
    display: inline-grid;
    width: 100%;
    max-width: 650px;
  }

  input, textarea {
    font-size: 18px;
    font-family: bely;
    border: unset;
    margin-bottom: 30px;
    outline: none;
    padding: 11px;
  }

  .form-submit-success {
    color: ${Colors.WHITE};
    font-size: 20px;
  }

  .form-submit-failure {
    color: ${Colors.WHITE};
    font-size: 20px;
  }
  
  .submit-button {
    display: inline-block;
    text-decoration: none;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 40px;
    padding-left: 40px;

    font-weight: 100;
    border-radius: 7px;
    width: 150px;
    line-height: 37px;
    text-align: center;
    border: solid ${Colors.WHITE} 2px;
    color: ${Colors.WHITE};
    background-color: ${Colors.BLACK};
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;

    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    margin-bottom: 0;
  }
  
  .submit-button:disabled {
    cursor: not-allowed;
    opacity: .5;
  }
  
  .submit-button:hover:enabled {
    background-color: ${Colors.WHITE};
    color: ${Colors.BLACK};
    cursor: pointer;
  }
`;
