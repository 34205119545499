import styled from "styled-components";

interface StyledMediumTitleProps {
    textColor: string;
    backgroundColor: string;
    withLines?: string;
    centered?: boolean;
}

export const StyledMediumTitle = styled.div<StyledMediumTitleProps>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  width: 250px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-family: bely;
  margin-left: ${props => props.centered ? "auto" : "unset"};
  margin-right: ${props => props.centered ? "auto" : "unset"};
`;
