import styled from "styled-components";
import {Colors} from "../../../../common/style/ColorUtils";
import {SM_MAX_SIZE, XS_MAX_SIZE} from "../../../../common/util/ViewSizeUtils";

export const StyledBookingsDescriptionTile = styled.div`
  .content {
    background-color: ${Colors.WHITE};
    color: ${Colors.BLACK};
    margin-left: auto;
    margin-right: auto;
    height: 250px;
    display: flex;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 70px;
    max-width: 800px;

    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;
    }
  }

  .description {
    font-size: 20px;
    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      font-size: 18px;
    }
  }

  .number {
    font-size: 260px;
    line-height: 250px;
    min-width: 150px;

    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      font-size: 150px;
      line-height: 150px;
      min-width: 90px;
    }
    
    font-weight: bold;
    font-variant-numeric: lining-nums;
    text-align: center;
  }

  .page-title {
    font-weight: bold;
    font-size: 40px;
    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      font-size: 27px;
      padding-bottom: 10px;
    }
  }

  .page-right-panel {
    text-align: left;
    margin-left: 54px;
    margin-top: 35px;

    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      margin-left: 5px;
      margin-top: 17px;
    }
  }
`;
