import styled from "styled-components";
import {Colors} from "../../../common/style/ColorUtils";
import {MD_MIN_SIZE, SM_MAX_SIZE} from "../../../common/util/ViewSizeUtils";

export const StyledBookingsContact = styled.div`
  padding: 50px 13px;

  .text-us {
    color: ${Colors.WHITE};
    @media only screen and (min-width: ${MD_MIN_SIZE}px) {
      white-space: pre-line;
      padding-bottom: 77px;
    }

    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      padding-top: 50px;
      padding-bottom: 80px;
      font-size: 22px;
    }

    text-align: center;
  }

  .contact-row {
    padding-top: 50px;
  }
`;
