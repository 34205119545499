import React, {FC} from "react";
import {Translate} from "../../../lang/Translate.component";
import {StyledMediumTitle} from "./MediumTitle.style";

interface MediumTitleProps {
    title: string;
    textColor: string;
    backgroundColor: string;
    withLines?: string;
    centered?: boolean;
}

export const MediumTitle: FC<MediumTitleProps> = (props: MediumTitleProps) => {
    return <StyledMediumTitle backgroundColor={props.backgroundColor} textColor={props.textColor}
                              withLines={props.withLines} centered={props.centered}>
        <Translate translationKey={props.title}/>
    </StyledMediumTitle>;
};
