import React, {FC, useEffect, useState} from "react";
import {StyledContactForm} from "./ContactForm.style";
import {translate} from "../../../../lang/TranslationUtils";
import {getBookingsLink} from "../../../../common/components/menu/service/Links.service";
import {Translate} from "../../../../lang/Translate.component";
import {isDefined} from "../../../../common/util/ObjectUtils";

interface ContactFormProps {
    className?: string;
}

export const ContactForm: FC<ContactFormProps> = (props: ContactFormProps) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");

    const [redirectPage, setRedirectPage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailure, setIsFailure] = useState(false);

    useEffect(() => {
        setRedirectPage(window.location.origin + getBookingsLink() + "?message=");
        setIsFailure(window.location.href.includes("error"));
        setIsSuccess(window.location.href.includes("message"));
        window.history.replaceState(null, "", window.location.origin + getBookingsLink());
    }, []);

    const emailValid = (): boolean =>
        isDefined(email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ));

    const isValid = (): boolean => {
        return name?.length > 2
            && emailValid()
            && content.length > 2
            && name?.length < 100
            && email?.length < 100
            && content?.length < 2000;
    }

    return <StyledContactForm className={props.className}>
        <form id="contactform" action="https://formsubmit.io/send/eb8cf84c-2e24-4624-b718-8a4e7ccaf0f2" method="POST">
            <input name="_redirect" type="hidden" id="name" value={redirectPage}/>

            <input
                value={name}
                onChange={e => setName(e.target.value)}
                name="name"
                type="text"
                id={translate("NAME_AND_SURENAME")}
                placeholder={translate("NAME_AND_SURENAME")}
            />
            <input
                value={email}
                onChange={e => setEmail(e.target.value)}
                name="email"
                type="email"
                id={translate("YOUR_EMAIL_ADDRESS")}
                placeholder={translate("YOUR_EMAIL_ADDRESS")}
            />
            <textarea
                value={content}
                onChange={e => setContent(e.target.value)}
                name="comment"
                id={translate("CONTENT")}
                rows={7}
                style={{resize: "none"}}
                placeholder={translate("CONTENT")}
            />
            <input name="_formsubmit_id" type="text" style={{display: "none"}}/>
            <input disabled={!isValid()} className={"submit-button"} value={translate("SEND")} type="submit"/>
        </form>

        {isSuccess && !isFailure && <div className={"form-submit-success"}>
            <Translate translationKey={"FORM_SUBMIT_SUCCESS"}/>
        </div>}

        {isFailure && <div className={"form-submit-failure"}>
            <Translate translationKey={"FORM_SUBMIT_FAILURE"}/>
        </div>}
    </StyledContactForm>;
};
