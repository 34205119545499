import React, {FC} from "react";
import {StyledBookingsMap} from "./BookingsMap.style";
import {MediumTitle} from "../../../common/components/mediumTitle/MediumTitle.component";
import {Colors} from "../../../common/style/ColorUtils";
import {translate} from "../../../lang/TranslationUtils";

interface BookingsMapProps {
}

export const BookingsMap: FC<BookingsMapProps> = (props: BookingsMapProps) => {
    return <StyledBookingsMap>
        <MediumTitle title={"WHERE_TO_FIND_US"} textColor={Colors.WHITE} backgroundColor={Colors.BLACK} centered={true}/>
        <iframe
            title={translate("GOOGLE_MAPS_TITLE")}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9774.465457084638!2d21.0131684!3d52.2321874!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a958957ff612607!2sLe%C5%9Bny%20Szept%20Tattoo%20%26%20Piercing!5e0!3m2!1spl!2spl!4v1672362833532!5m2!1spl!2spl"
            width="100%" height="600" style={{border: 0}} allowFullScreen={false} loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
    </StyledBookingsMap>;
};
