import React, {FC} from "react";
import {StyledBookingsContact} from "./BookingsContact.style";
import {ContactForm} from "./contactForm/ContactForm.component";
import {MediumTitle} from "../../../common/components/mediumTitle/MediumTitle.component";
import {Colors} from "../../../common/style/ColorUtils";
import {ContactInformation} from "./contactInformation/ContactInformation.component";
import {Translate} from "../../../lang/Translate.component";

interface BookingsContactProps {
}

export const BookingsContact: FC<BookingsContactProps> = (props: BookingsContactProps) => {
    return <StyledBookingsContact>
        <MediumTitle title={"CONTACT"} textColor={Colors.BLACK} backgroundColor={Colors.WHITE} centered={true}/>
        <div className={"row middle-xs contact-row"}>
            <ContactInformation className={"col-xs-12 col-sm-12 col-md-5 col-lg-5"}/>
            <div className={"col-xs-12 col-sm-12 col-md-2 col-lg-2 text-us"}>
                <div><Translate translationKey={"TEXT_US"}/></div>
            </div>
            <ContactForm className={"col-xs-12 col-sm-12 col-md-5 col-lg-5"}/>
        </div>
    </StyledBookingsContact>;
};
