import styled from "styled-components";

export const StyledBookingsMap = styled.div`
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;

  iframe {
    padding-top: 50px;
  }
`;
