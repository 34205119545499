import styled from "styled-components";
import {Colors} from "../../../../common/style/ColorUtils";
import {MD_MIN_SIZE, SM_MAX_SIZE} from "../../../../common/util/ViewSizeUtils";

export const StyledContactInformation = styled.div`
  text-align: center;
  color: ${Colors.WHITE};
  font-size: 25px;
  @media only screen and (min-width: ${MD_MIN_SIZE}px) {
    padding-bottom: 77px;
  }
  
  .contact-information {
    display: inline-grid;
  }

  .contact-information-row {
    display: inline-flex;
    padding-bottom: 30px;
  }

  .contact-information-text {
    color: ${Colors.WHITE};
    padding-left: 10px;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }

  .contact-information-icon {
    width: 25px;
    text-align: center;
  }

  .contact-information-text:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;
  }

  .line-top, .line-bottom, .line-left, .line-right {
    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      display: none;
    }
  }

`;
