import styled from "styled-components";
import {Colors} from "../../../common/style/ColorUtils";
import {SM_MAX_SIZE, XS_MAX_SIZE} from "../../../common/util/ViewSizeUtils";

export const StyledBookingsDescription = styled.div`
  text-align: center;
  padding-top: 50px;
  background-color: white;
  margin-bottom: 120px;
  max-width: 80vw;

  @media only screen and (max-width: ${SM_MAX_SIZE}px) {
    max-width: 90vw;
  }

  @media only screen and (max-width: ${XS_MAX_SIZE}px) {
    max-width: 100vw;
  }

  margin-left: auto;
  margin-right: auto;

  .bookings-description {
    color: ${Colors.BLACK};
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 20px;
    font-family: bely;
    white-space: pre-wrap;

    @media only screen and (max-width: ${820}px) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .instructions {
    padding-top: 60px;
  }
`;
